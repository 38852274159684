<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink={{homePage}}>
        <!-- <img src="assets/images/logo.png" alt="" /> -->
        <span class="logo-name">Msrtc</span>
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngClass]="isNavbarCollapsed ? '' : 'show'">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-button (click)="callSidemenuCollapse()" class="sidemenu-collapse nav-notification-icons"> <i
              class="material-icons-outlined icon-color">
              menu
            </i></button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-button class="nav-notification-icons" (click)="callFullscreen()"> <i
              class="material-icons-outlined icon-color">
              fullscreen
            </i></button>
        </li>
        <!-- #END# Full Screen Button -->
        <!-- #START# Notifications-->
        <li>
          <button mat-button [matMenuTriggerFor]="notificationMenu" class="nav-notification-icons"> <i
              class="material-icons-outlined icon-color">
              notifications_active
            </i></button>
          <mat-menu #notificationMenu="matMenu" class="nfc-menu" xPosition="before">
            <div class="nfc-header">
              <h5 class="mb-0">Notitications</h5>
              <a class="nfc-mark-as-read">Mark all as read</a>
            </div>
            <div class="nfc-dropdown">
              <ng-scrollbar style="height: 350px" visibility="hover">
                <div class="noti-list header-menu">
                  <div class="menu">
                    <div>
                      <button mat-menu-item *ngFor="let notification of notifications" onClick="return false;"
                        [ngClass]="[notification.status]">
                        <span class="table-img msg-user ">
                          <i class="material-icons-two-tone nfc-type-icon"
                            [ngClass]="[notification.color]">{{notification.icon}}</i>
                        </span>
                        <span class="menu-info">
                          <span class="menu-title">{{notification.message}}</span>
                          <span class="menu-desc mt-2">
                            <i class="material-icons">access_time</i> {{notification.time}}
                          </span>
                        </span>
                        <span class="nfc-close">
                          <app-feather-icons [icon]="'x'" [class]="'user-menu-icons'"></app-feather-icons>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-scrollbar>
            </div>
            <div class="nfc-footer">
              <a class="nfc-read-all">Read
                All Notifications</a>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profilemenu">
            <div class="chip dropdown-toggle" ngbDropdownToggle class="">
              <span>{{name}}</span>
              <img src="assets/images/user/user.png" class="user_img" width="32" height="32" alt="User">
            </div>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu ">
                <div class="user_dw_menu">
                  <button mat-menu-item>
                    <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Account
                  </button>
                  <button mat-menu-item>
                    <app-feather-icons [icon]="'mail'" [class]="'user-menu-icons'"></app-feather-icons>Inbox
                  </button>
                  <button mat-menu-item>
                    <app-feather-icons [icon]="'settings'" [class]="'user-menu-icons'"></app-feather-icons>Settings
                  </button>
                  <button mat-menu-item (click)="logout()">
                    <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>
